<template>
  <footer class="footer pb-4">
    <v-container class="containers" fluid>
      <div class="footer__logo">
        <img src="@/static/images/VectorLead.svg" alt="ЛИДПОРТАЛ" />
      </div>

      <div class="row">
        <div class="col-12 col-md order-1 order-md-0">
          <span class="lead_rights"
            >Leadportal {{ year }} © Все права защищены</span
          >
        </div>
        <div class="col-12 col-md-auto ml-md-auto order-0 order-md-1">
          <a
            href="/политика_обработки_персональных_данных.pdf"
            target="_blank"
            class="mr-10"
            ><b>Политика конфиденциальности</b>
          </a>
          <a
            href="/Руководство_пользователя.pdf"
            class="d-block"
            target="_blank"
            ><b>Инструкция пользователя</b>
          </a>
        </div>
      </div>

      <div class="footer__line"></div>

      <div class="footer__small">
        <p>
          ООО "Лидпортал" 2023 - {{ year }}. ИНН 9710120203 ОГРН 1237700061676,
          Москва, 4-я Тверская-Ямская 6/12
        </p>
        <p>
          <!-- Информация о регистрации в Роспатенте <br /> -->
          Свидетельство о государственной регистрации программы для ЭВМ в
          Роспатенте № 2024664426 от 20.06.2024
          <!-- внесено в реестр Российского ПО за номером TBD, дата записи TBD -->
        </p>
      </div>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: "FooterLinks",
  data() {
    const year = new Date().getFullYear()
    return {
      year,
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_vars.scss";

.container {
}

.footer {
  background-color: #f5f5f5;

  padding: 60px 0 40px;
  font-size: 20px;
  @media (max-width: $xl) {
    padding: 30px 0 0px;
    font-size: 14px;
  }

  &__logo {
    padding-bottom: 60px;
    @media (max-width: $xl) {
      padding-bottom: 20px;
    }

    img {
      max-width: 100%;
    }
  }

  &__line {
    border-bottom: 1px solid #828282;
    padding: 40px 0 0;
    margin: 0 0 40px;
  }

  &__small {
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    font-size: 14px;
    @media (max-width: $xl) {
      font-size: 12px;
    }
  }
}
</style>
